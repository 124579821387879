import gql from 'graphql-tag'
import {
  getTemplatesFiltersValuesList,
  searchWordsQuery,
  durationRangeQuery,
  orderByQuery,
  getConstructedStmtValues,
  getTemplatesTypes,
  getTemplatesUseCases,
  selectedActionQuery,
  partnerQuery,
  isBasicQuery
} from '../graphql/templates'
import { GET_USER_IMAGE_TEMPLATE } from '../graphql/userTemplates'
import {
  filtersTypes,
  stmtTypes,
  templateCacheDefaults,
  templateDuration
} from '../lib/constants/template'

const generateFilterSelectionDefaults = () => {
  const isSelected = () => false
  const resolvers = {}
  filtersTypes.forEach((filter) => {
    resolvers[filter] = { isSelected }
  })
  return resolvers
}

const generateStmtSelectionDefaults = () => {
  const isSelected = () => false
  const resolvers = {}
  stmtTypes.forEach((phraseType) => {
    resolvers[phraseType] = { isSelected }
  })
  return resolvers
}

const getSupportedFilters = (cache) => {
  const supportedFilters = cache.readQuery({
    query: getTemplatesFiltersValuesList
  })

  return supportedFilters
}

const applyFilterSelections = (
  cache,
  getCacheKey,
  supportedFilters,
  userFilters
) => {
  Object.keys(userFilters).forEach((uFilter) => {
    if (supportedFilters[uFilter]) {
      const supportedValues =
        supportedFilters[uFilter].edges || supportedFilters[uFilter]

      supportedValues.forEach((value) => {
        const supportedValue = value.node || value
        if (
          (typeof userFilters[uFilter] === 'string' &&
            supportedValue.slug === userFilters[uFilter]) ||
          userFilters[uFilter].indexOf(supportedValue.slug) !== -1
        ) {
          const id = getCacheKey({
            __typename: supportedValue.__typename,
            id: supportedValue.id
          })
          cache.writeData({
            id,
            data: {
              ...supportedValue,
              isSelected: true
            }
          })
        }
      })
    }

    if (uFilter === 'duration') {
      const { min, max } = userFilters.duration
      cache.writeData({
        data: {
          minDuration: min || templateDuration.MIN,
          maxDuration: max || templateDuration.MAX
        }
      })
    }
  })
}

const resolvers = {
  ...generateFilterSelectionDefaults(),
  ...generateStmtSelectionDefaults(),
  /**
   * eg:
   * TemplateCategory: {
   *    isSelected: () => false;
   * }
   */

  Query: {
    getSelectedFilters: (_, __, { cache }) => {
      let selectedFilters = {}

      const supportedFilters = getSupportedFilters(cache)

      Object.keys(supportedFilters).forEach((filter) => {
        const filterValues =
          supportedFilters[filter].edges || supportedFilters[filter]
        filterValues.forEach((value) => {
          const { slug, name, isSelected } = value.node || value
          if (isSelected)
            selectedFilters[filter] = selectedFilters[filter]
              ? [...selectedFilters[filter], slug || name]
              : [slug || name]
        })
      })

      // if the images are only selected then disregard the duration filters
      if (
        selectedFilters.types &&
        typeof selectedFilters.types === 'object' &&
        selectedFilters.types.length === 1 &&
        selectedFilters.types[0] === 'video'
      ) {
        const { minDuration, maxDuration } = cache.readQuery({
          query: durationRangeQuery
        })
        if (minDuration !== 0)
          selectedFilters = { ...selectedFilters, minDuration }
        if (maxDuration !== 300)
          selectedFilters = { ...selectedFilters, maxDuration }
      }

      const { partnerOnly } = cache.readQuery({
        query: partnerQuery
      })
      selectedFilters = { ...selectedFilters, partnerOnly }

      const { isBasic } = cache.readQuery({
        query: isBasicQuery
      })
      selectedFilters = { ...selectedFilters, isBasic }
      const { searchQuery } = cache.readQuery({
        query: searchWordsQuery
      })
      if (searchQuery.length !== 0)
        selectedFilters = { ...selectedFilters, searchQuery }

      const { orderBy } = cache.readQuery({
        query: orderByQuery
      })
      selectedFilters = { ...selectedFilters, orderBy }

      return selectedFilters
    },
    isVideosOnly: (_, __, { cache }) => {
      let videosFilter = false
      let imagesFilter = false

      const { types } = cache.readQuery({
        query: getTemplatesTypes
      })

      types.forEach((type) => {
        const { slug, isSelected } = type

        if (slug === 'image' && isSelected) imagesFilter = true
        if (slug === 'video' && isSelected) videosFilter = true
      })

      return videosFilter && !imagesFilter
    },
    selectedAction: (_, __, { cache }) => {
      const { actions } = cache.readQuery({
        query: getConstructedStmtValues
      })
      let selectedAction = null
      if (actions)
        actions.forEach(({ slug, isSelected }) => {
          if (isSelected) selectedAction = slug
        })
      return selectedAction
    },
    selectedPhrases: (_, __, { cache }) => {
      const selectedPhrases = {}

      const { selectedAction } = cache.readQuery({
        query: selectedActionQuery
      })
      if (selectedAction) {
        selectedPhrases.actions = selectedAction

        const { useCases } = cache.readQuery({
          query: getTemplatesUseCases,
          variables: { action: selectedAction }
        })

        if (useCases) {
          useCases.forEach((useCase) => {
            if (useCase.isSelected) selectedPhrases.useCases = useCase.slug
          })
        }
      }

      const { minDuration, maxDuration } = cache.readQuery({
        query: durationRangeQuery
      })
      selectedPhrases.minDuration = minDuration
      selectedPhrases.maxDuration = maxDuration

      const { types, platforms } = cache.readQuery({
        query: gql`
          query remainingFilters {
            types @client {
              isSelected
              slug
            }
            platforms @client {
              isSelected
              slug
            }
          }
        `
      })

      if (types)
        types.forEach(({ isSelected, slug }) => {
          if (isSelected) selectedPhrases.types = slug
        })

      if (platforms)
        platforms.forEach(({ isSelected, slug }) => {
          if (isSelected) selectedPhrases.platforms = slug
        })

      return selectedPhrases
    }
  },

  Mutation: {
    updateUserStorage: (
      _,
      { id, newConsumedStorage },
      { cache, getCacheKey }
    ) => {
      const __typename = 'UserConsumption'
      const cachedKey = getCacheKey({
        __typename,
        id
      })
      cache.writeData({
        id: cachedKey,
        data: {
          consumedStorage: newConsumedStorage
        }
      })
    },
    putSelectedPhrases: (_, { statement }, { cache, getCacheKey }) => {
      const consturctedStmtValues = cache.readQuery({
        query: getConstructedStmtValues
      })
      let useCasesValues = {}
      if (statement.actions && statement.useCases) {
        useCasesValues = cache.readQuery({
          query: getTemplatesUseCases,
          variables: { action: statement.actions }
        })
      }
      applyFilterSelections(
        cache,
        getCacheKey,
        { ...consturctedStmtValues, ...useCasesValues },
        statement
      )
      return true
    },
    putSelectedFilters: (_, { userFilters }, { cache, getCacheKey }) => {
      const supportedFilters = getSupportedFilters(cache)
      applyFilterSelections(cache, getCacheKey, supportedFilters, userFilters)

      // cache.writeData({
      //   data: { ...userListingRefiners }
      // });
      return true
    },
    toggleSelectedFilter: (_, { id, __typename }, { cache, getCacheKey }) => {
      const cachedKey = getCacheKey({
        __typename,
        id
      })

      const fragment = gql`
        fragment getIsSelected on ${__typename} {
          isSelected
        }
      `
      const { isSelected } = cache.readFragment({
        fragment,
        id: cachedKey
      })
      cache.writeData({
        id: cachedKey,
        data: {
          isSelected: !isSelected
        }
      })
    },
    clearSelectedFilters: (_, __, { cache, getCacheKey }) => {
      const supportedFilters = getSupportedFilters(cache)

      Object.keys(supportedFilters).forEach((filter) => {
        const supportedValues =
          supportedFilters[filter].edges || supportedFilters[filter]
        supportedValues.forEach((value) => {
          const supportedValue = value.node || value
          const id = getCacheKey({
            __typename: supportedValue.__typename,
            id: supportedValue.id
          })
          cache.writeData({
            id,
            data: {
              ...supportedValue,
              isSelected: false
            }
          })
        })
      })

      cache.writeData({
        data: { ...templateCacheDefaults }
      })

      return true
    },
    toggleSelectedPhrase: (_, { id, dataArr }, { cache, getCacheKey }) => {
      const { __typename } = dataArr[0]
      if (__typename === 'TemplateAction') {
        const { selectedAction } = cache.readQuery({
          query: selectedActionQuery
        })

        if (selectedAction) {
          const { useCases } = cache.readQuery({
            query: getTemplatesUseCases,
            variables: { action: selectedAction }
          })

          if (useCases) {
            useCases.forEach((useCase) => {
              const cachedKey = getCacheKey({
                __typename: useCase.__typename,
                id: useCase.id
              })
              cache.writeData({
                id: cachedKey,
                data: {
                  isSelected: false
                }
              })
            })
          }
        }
      }

      dataArr.forEach((phraseValue) => {
        const cachedKey = getCacheKey({
          __typename: phraseValue.__typename,
          id: phraseValue.id
        })

        const isSelected = phraseValue.id === id
        cache.writeData({
          id: cachedKey,
          data: {
            isSelected
          }
        })
      })
    },
    updateUserTemplateSceneImages: (
      _,
      { id, templateId, backgroundUrl, thumbnailUrl },
      { cache }
    ) => {
      const { userImageTemplate } = cache.readQuery({
        query: GET_USER_IMAGE_TEMPLATE,
        variables: {
          id: templateId
        }
      })
      const updatedScenes = userImageTemplate.scenes.map((scene) =>
        scene.id === id ? { ...scene, backgroundUrl, thumbnailUrl } : scene
      )
      cache.writeQuery({
        query: GET_USER_IMAGE_TEMPLATE,
        data: { ...userImageTemplate, scenes: updatedScenes }
      })
    }
  }
}

export default resolvers
